import React from "react"
import { Link } from "gatsby"

const LengthGuideRow = ({ pairs, smal, medium, wide }) => {
  return (
    <tr>
      <td>{pairs}</td>
      <td>
        <Link to={"/kopa-skosnoren-online/?the_length=" + smal + "#filter"}>
          {smal} cm
        </Link>
      </td>
      <td>
        <Link to={"/kopa-skosnoren-online/?the_length=" + medium + "#filter"}>
          {medium} cm
        </Link>
      </td>
      <td>
        <Link to={"/kopa-skosnoren-online/?the_length=" + wide + "#filter"}>
          {wide} cm
        </Link>
      </td>
    </tr>
  )
}

export default LengthGuideRow
