import config from "./config"

function createProductUrl(productNode) {
  return config.productPath + "/" + productNode.fields.full_name_url + "/"
}

function makeStringUrlFriendly(value) {
  var newValue = value
  newValue = newValue.replace(/[åä]+/gi, "a")
  newValue = newValue.replace(/[ÅÄ]+/gi, "A")
  newValue = newValue.replace(/[é]+/gi, "e")
  newValue = newValue.replace(/[É]+/gi, "E")
  newValue = newValue.replace(/[ö]+/gi, "o")
  newValue = newValue.replace(/[Ö]+/gi, "O")
  return encodeURIComponent(
    newValue.toLowerCase().replace(/[^a-z0-9_-]+/gi, "-")
  )
}

function createBrandUrl(brand) {
  // TODO: Should use brand_url instead
  return "/marken/" + makeStringUrlFriendly(brand.toLowerCase()) + "/"
}

function createCompareProductUrl(productNode1, productNode2) {
  var newProductNode1, newProductNode2
  if (productNode1.fields.full_name <= productNode2.fields.full_name) {
    newProductNode1 = productNode1
    newProductNode2 = productNode2
  } else {
    newProductNode1 = productNode2
    newProductNode2 = productNode1
  }

  return (
    config.productPath +
    "/" +
    newProductNode1.fields.full_name_url +
    "/vs/" +
    newProductNode2.fields.full_name_url
  )
}

function generateSemiRandomNumber(number, digit_use, count) {
  return parseInt(("" + number)[digit_use]) % count
}

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
}

function capitalizeFirstLetter(string) {
  if (typeof string === "string" || string instanceof String) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  } else {
    return string
  }
}

export {
  createProductUrl,
  createCompareProductUrl,
  createBrandUrl,
  generateSemiRandomNumber,
  numberWithSpaces,
  capitalizeFirstLetter,
}
