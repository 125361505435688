import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./categoriesLinks.css"

function capitalizeTheFirstLetterOfEachWord(words) {
  var separateWord = words.toLowerCase().split(" ")
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }
  return separateWord.join(" ")
}

const CategoriesLinks = () => {
  const queryData = useStaticQuery(graphql`
    query categoriesLinksQuery {
      site {
        siteMetadata {
          categoryPages {
            link
            name
          }
          categoryPagesTexts
        }
      }
    }
  `)

  const categoryPages = queryData.site.siteMetadata.categoryPages
  const categoryPagesTexts = queryData.site.siteMetadata.categoryPagesTexts

  return (
    <div id="catagories">
      {categoryPages.map((categoryPageList, index) => (
        <div key={index}>
          {categoryPagesTexts[index]}
          <br />
          <ul className="category_menu">
            {categoryPageList.map(link => (
              <li key={link.name}>
                <Link to={link.link}>
                  {capitalizeTheFirstLetterOfEachWord(link.name)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default CategoriesLinks
