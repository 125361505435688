import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../../../src/components/productDisplay.js";
import CategoriesLinks from "../../../../src/components/categoriesLinks.js";
import * as React from 'react';
export default {
  graphql,
  Link,
  ProductsDisplay,
  CategoriesLinks,
  React
};