import React from "react"
import "./filter.css"

const DropDown = ({
  name,
  options,
  title,
  callback,
  selectedValue,
  includeAll = true,
}) => {
  let allOption = ""
  if (includeAll) {
    allOption = (
      <option key="alla" value="alla">
        alla
      </option>
    )
  }
  return (
    <label htmlFor={title}>
      {title + ": "}
      <select
        name={title}
        id={name}
        onChange={e => callback(e)}
        value={selectedValue}
      >
        {allOption}
        {options.map(function (option, index) {
          return (
            <option
              key={index}
              value={option[0]}
              className={option[1] === 0 ? "empty" : ""}
            >
              {option[0] + " (" + option[1] + ")"}
            </option>
          )
        })}
      </select>
    </label>
  )
}

export default DropDown
